import React from "react";
import {
  FormikHandlers,
  FormikHelpers,
  FormikErrors,
  getIn,
  FieldArrayRenderProps,
} from "formik";

// material ui
import { Grid, TextField, MenuItem, FormControlLabel } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import {
  ClientQuestionnaireDto,
  SeparationPaymentDto,
  SeparationPaymentInit,
} from "../dto";
import {
  claimantSeparationPayOptions,
  radioButtonBooleanOptions,
  frequencyOfPayOptions,
} from "../constants/options";
import { MHARadio, PaddedTextField } from "../../../common/styles/styles";

interface Props extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  payment: SeparationPaymentDto;
  errors: FormikErrors<ClientQuestionnaireDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ClientQuestionnaireDto>["setFieldValue"];
  setShouldBlockNavigation: (o: boolean) => void;
  arrayHelpers: FieldArrayRenderProps;
  index: number;
}

const SeparationPayment: React.FC<Props> = ({
  values,
  payment,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  setShouldBlockNavigation,
  arrayHelpers,
  index,
}) => {
  const [addSeparationPayment, setAddSeparationPayment] = React.useState<
    boolean | undefined
  >();

  React.useEffect(() => {
    if (index < (values.payments ?? []).length - 1)
      setAddSeparationPayment(true);
  }, [values, index]);

  React.useEffect(() => {
    handleSeparationPaymentChange();
  }, [addSeparationPayment]);

  const handleSeparationPaymentChange = () => {
    let checkIndex = (values.payments ?? []).length - 1;
    if (addSeparationPayment === true) {
      if (checkIndex === index) arrayHelpers.push(SeparationPaymentInit);
    } else if (addSeparationPayment === false) {
      let indexTodelete = checkIndex;
      while (index !== indexTodelete && indexTodelete > 0) {
        arrayHelpers.remove(indexTodelete);
        indexTodelete--;
      }
    }
  };

  return (
    <>
      <Grid item xs={12}>
        Type of separation pay
      </Grid>
      <Grid item md={12} xs={12} sm={12} lg={4}>
        <PaddedTextField
          id={`payments[${index}].separationPayTypeId`}
          size="small"
          margin="none"
          select
          value={payment.separationPayTypeId || ""}
          name={`payments[${index}].separationPayTypeId`}
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(
            getIn(errors, `payments[${index}].separationPayTypeId`)
          )}
          helperText={
            getIn(errors, `payments[${index}].separationPayTypeId`) &&
            getIn(errors, `payments[${index}].separationPayTypeId`)
          }
          data-cy={`clientQuestionnaire-payments-${index}-separationPayTypeId`}
          aria-label="Type of separation pay"
          fullWidth
          SelectProps={{ MenuProps: { disableScrollLock: true } }}
        >
          {claimantSeparationPayOptions
            .filter((item) => item.name !== "")
            .map((option, index) => (
              <MenuItem key={option.name + index} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
        </PaddedTextField>
      </Grid>
      <br />
      <Grid item xs={12}>
        What is the amount of the pay (Please provide total gross amount paid,
        regardless of frequency of the payment)?
      </Grid>
      <Grid item xs={4}>
        <TextField
          id={`payments[${index}].amountOfPay`}
          size="small"
          margin="none"
          value={payment.amountOfPay || ""}
          name={`payments[${index}].amountOfPay`}
          multiline
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(getIn(errors, `payments[${index}].amountOfPay`))}
          helperText={
            getIn(errors, `payments[${index}].amountOfPay`) &&
            getIn(errors, `payments[${index}].amountOfPay`)
          }
          data-cy={`clientQuestionnaire-payments-${index}-amountOfPay`}
          aria-label="Amount of pay"
          fullWidth
        />
      </Grid>
      <br />
      <Grid item xs={12}>
        What is/was the frequency of the payment?
      </Grid>
      <Grid item md={12} xs={12} sm={12} lg={4}>
        <PaddedTextField
          id={`payments[${index}].frequencyOfPayId`}
          size="small"
          margin="none"
          select
          value={payment.frequencyOfPayId || ""}
          name={`payments[${index}].frequencyOfPayId`}
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(getIn(errors, `payments[${index}].frequencyOfPayId`))}
          helperText={
            getIn(errors, `payments[${index}].frequencyOfPayId`) &&
            getIn(errors, `payments[${index}].frequencyOfPayId`)
          }
          data-cy={`clientQuestionnaire-payments-${index}-frequencyOfPayId`}
          aria-label="Type of separation pay"
          fullWidth
          SelectProps={{ MenuProps: { disableScrollLock: true } }}
        >
          {frequencyOfPayOptions
            .filter((item) => item.name !== "")
            .map((option, index) => (
              <MenuItem key={option.name + index} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
        </PaddedTextField>
      </Grid>
      <br />
      <Grid item xs={12}>
        On what date(s) was the payment issued?
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={4}>
        <KeyboardDatePicker
          margin="normal"
          id={`payments[${index}].paymentIssuedDate`}
          name={`payments[${index}].paymentIssuedDate`}
          format="MM/DD/yyyy"
          value={payment.paymentIssuedDate || null}
          data-cy="clientQuestionnaire_paymentIssuedDate"
          fullWidth
          onChange={(date: MaterialUiPickersDate) =>
            setFieldValue(`payments[${index}].paymentIssuedDate`, date)
          }
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </Grid>
      <br />
      <Grid item xs={12}>
        Was the payment allocated to cover a particular time period? if so, what
        time period?
      </Grid>
      <Grid item xs={4}>
        <TextField
          id={`payments[${index}].paymentTimePeriod`}
          name={`payments[${index}].paymentTimePeriod`}
          size="small"
          margin="none"
          value={payment.paymentTimePeriod || ""}
          multiline
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={(e) => {
            handleBlur(e);
            setShouldBlockNavigation(true);
          }}
          error={Boolean(getIn(errors, `payments[${index}].paymentTimePeriod`))}
          helperText={
            getIn(errors, `payments[${index}].paymentTimePeriod`) &&
            getIn(errors, `payments[${index}].paymentTimePeriod`)
          }
          data-cy={`clientQuestionnaire-payments-${index}-paymentTimePeriod`}
          aria-label="Payment time period"
          fullWidth
        />
      </Grid>
      <br />

      <Grid item xs={12}>
        Besides what is listed above, were any other types of separation pay
        issued upon separation?
      </Grid>

      <Grid container item xs={12} direction="row">
        <Grid item xs={3}>
          <FormControlLabel
            onChange={(
              e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>,
              checked: boolean
            ) => {
              setAddSeparationPayment(false);
              setShouldBlockNavigation(true);
            }}
            onBlur={handleBlur}
            checked={!addSeparationPayment}
            control={<MHARadio size="small" />}
            label="No"
          />
        </Grid>

        <Grid item xs={3}>
          <FormControlLabel
            onChange={(
              e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>,
              checked: boolean
            ) => {
              setAddSeparationPayment(true);
              setShouldBlockNavigation(true);
            }}
            onBlur={handleBlur}
            checked={
              addSeparationPayment || index < (values.payments ?? []).length - 1
            }
            control={<MHARadio size="small" />}
            label="Yes"
          />
        </Grid>
      </Grid>

      <br />
      <br />
    </>
  );
};

export default SeparationPayment;
